import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { SharedGuardService } from "./shared-guard.service";
import { firstValueFrom } from "rxjs";

export const kycGuard: CanActivateFn = async () => {
  const router = inject(Router);
  const sharedGuardService = inject(SharedGuardService);

  const user = await sharedGuardService.hasUser();

  if (!user) {
    return router.parseUrl("/login");
  }

  const requiresVerification = await sharedGuardService.requiresVerification();

  if (requiresVerification) {
    return true;
  }

  if ((await firstValueFrom(sharedGuardService.organization)).isSuspended) {
    return router.parseUrl("/suspended-account");
  }

  return router.parseUrl("/profile-setup");
};
