import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { SharedGuardService } from "./shared-guard.service";

export const isPaymentOwnerGuard: CanActivateFn = async (route) => {
  const router = inject(Router);
  const sharedGuardService = inject(SharedGuardService);

  // Check if payment is associated with the current organization
  const payment = sharedGuardService.getSubscriptionPayment();

  if (!payment || route.params["paymentId"] !== payment) {
    return router.parseUrl("/dashboard");
  }

  return true;
};
