import { ChangeDetectionStrategy, Component, effect, inject, HostBinding, PLATFORM_ID } from "@angular/core";
import { TuiRootModule, TuiDialogModule, TuiThemeNightModule, TuiModeModule, TuiAlertModule } from "@taiga-ui/core";
import { AuthService } from "./domain/auth/auth.service";
import { IntercomService } from "./domain/support/intercom.service";
import { ThemeSwitcherService } from "./feature/theme-switcher.service";
import { TuiLetModule } from "@taiga-ui/cdk";
import { firstValueFrom } from "rxjs";
import { Dialog, DialogModule } from "@angular/cdk/dialog";
import { ConsentService } from "./domain/legal/consent.service";
import { RouterModule } from "@angular/router";
import { CookieDialogComponent } from "./ui/dialog/cookie/cookie-dialog.component";
import { I18nService } from "./domain/i18n/i18n.service";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { isPlatformBrowser } from "@angular/common";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "dev-portal-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  imports: [
    TuiRootModule,
    TuiDialogModule,
    TuiModeModule,
    TuiThemeNightModule,
    TuiLetModule,
    DialogModule,
    RouterModule,
    TuiAlertModule
  ],
  standalone: true
})
export class AppComponent {
  themeModeSwitcher = inject(ThemeSwitcherService);
  private dialog = inject(Dialog);
  private consentService = inject(ConsentService);
  private authService = inject(AuthService);
  private intercom = inject(IntercomService);
  private i18nService = inject(I18nService);

  @HostBinding("style.visibility") private get isVisibile() {
    return this.i18nService.translationLoaded() ? "visible" : "hidden";
  }

  constructor(googleAnalyticsService: GoogleAnalyticsService, gtmService: GoogleTagManagerService) {
    if (isPlatformBrowser(inject(PLATFORM_ID))) {
      this.intercom.init();
      this.intercom.boot();
      gtmService.addGtmToDom().then(() => {
        const consentStatus = this.consentService.hasAcceptedCookies() ? "granted" : "denied";
        googleAnalyticsService.gtag("consent", "default", {
          ad_storage: consentStatus,
          analytics_storage: consentStatus
        });
      });
      effect(() => {
        const user = this.authService.currentUser();
        if (user) {
          this.intercom.update({
            email: user.emailAddress,
            name: `${user.givenName} ${user.familyName}`,
            user_id: user.id
          });
        } else {
          this.intercom.shutdown();
          this.intercom.boot();
        }
      });

      if (!this.consentService.hasAcceptedCookies() && !this.consentService.hasRejectedCookies()) {
        this.openCookieDialog().then((result) => {
          if (result) {
            this.acceptCookie();
          } else {
            this.consentService.acceptCookies("deny");
          }
        });
      }
    }
  }

  acceptCookie() {
    this.consentService.acceptCookies("allow");
  }

  openCookieDialog() {
    const ref = this.dialog.open<boolean>(CookieDialogComponent, {
      autoFocus: false,
      hasBackdrop: false,
      disableClose: true
    });
    return firstValueFrom(ref.closed);
  }
}
