import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router } from "@angular/router";
import { SharedGuardService } from "./shared-guard.service";
import { firstValueFrom, map, skipWhile, take } from "rxjs";
import { hasRole, Role } from "@libs/shares/models/auth.model";
import { ProfileService } from "../profile/profile.service";

export const surveyGuard: CanActivateFn = async (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const sharedGuardService = inject(SharedGuardService);
  const profileService = inject(ProfileService);

  const user = await firstValueFrom(sharedGuardService.user);

  if (!user) {
    return router.parseUrl("/login");
  }

  if (!hasRole(Role.Owner, user.scopes)) {
    return firstValueFrom(
      profileService.organization$.pipe(
        skipWhile((organizations) => organizations === undefined),
        take(1),
        map((organizations) => {
          if (organizations?.[0].isSuspended && route.data["skipBlockRedirect"] !== false) {
            return router.parseUrl("/suspended-acccount");
          }

          return router.parseUrl("/dashboard");
        })
      )
    );
  }

  const surveyList = await firstValueFrom(sharedGuardService.requiredSurveyList);
  // on the url survey
  if (surveyList.length === 0) {
    return router.parseUrl("/dashboard");
  } else {
    return true;
  }
};
